<template>
  <div class="analysis-content">
    <div class="top-bar">
      <div class="right-bar"></div>
      <div class="left-bar"></div>
      <div class="return-btn">
        <span @click="$router.back(-1)" class="return-text">
          <i class="v-icon-back return-icon"></i>
          返回上一级
        </span>
      </div>
    </div>
    <div class="content-analysis">
      <div class="top-content">
        <div class="byq-info">
          <div class="title">
            <div class="img">
              <el-image
                style="width: 60%; height: auto; z-index: -1"
                :src="titleSrc"
              />
            </div>
            <div class="text">
              <span style="margin-left: 30px">设备信息</span>
              <hr />
            </div>
          </div>
          <div class="byq-content">
            <div class="top">
              <div class="image"><el-image :src="equipmentImage" /></div>
              <el-descriptions
              direction="vertical"
              size="small"
                :column="2"
                border
                style="background-color: rgb(3, 33, 92)"
            >
            <el-descriptions-item label="生产日期">{{equipmentInfo.start_up}}</el-descriptions-item>
            <el-descriptions-item label="容量">{{equipmentInfo.capacity}}</el-descriptions-item>
            <el-descriptions-item label="规格型号">{{equipmentInfo.equipment_type}}</el-descriptions-item>
            <el-descriptions-item label="运行工时">{{equipmentInfo.workHours}}</el-descriptions-item>
          </el-descriptions>
              <div class="center">
                <div class="block display-align">
                  <span class="d-flex all-style">环境温度:23~27℃</span>
                  <span class="d-flex all-style">运行时间:333h</span>
                  <span class="d-flex all-style">负载率：56%（22MVA）</span>
                  <span class="d-flex all-style">过负载能力：109%</span>
                </div>
              </div>
            </div>
            <div class="bottom">
              <div class="d-flex">
                <el-button
                  class="button-3d-1"
                  @click="changeRealTableData('pd')"
                  :style="{
                    background:
                    curClickBtn == 'pd'
                        ? '#00c1de'
                        : '',
                  }"
                  >局放诊断</el-button
                >
                <el-button
                  class="button-3d-1"
                  @click="changeRealTableData('tem')"
                  :style="{
                    background:
                    curClickBtn == 'tem'
                        ? '#00c1de'
                        : '',
                  }"
                  >温度诊断</el-button
                >
                <el-button
                  class="button-3d-1"
                  @click="changeRealTableData('vibra')"
                  :style="{
                    background:
                    curClickBtn == 'vibra'
                        ? '#00c1de'
                        : '',
                  }"
                  >振动诊断</el-button
                >
              </div>
              <div
                class="d-flex"
                ref="tableContainer"
                :style="{ height: tableHeight + 'px' }"
              >
                <el-table
                  class="table-layout"
                  border
                  header-align="center"
                  :height="tableHeight"
                  :data="sensorTable"
                  style="width: 95%; margin: 0 auto"
                >
                  <el-table-column prop="sensor_position" label="部件名称">
                  </el-table-column>
                  <el-table-column prop="sensor_num" label="传感器编号">
                  </el-table-column>
                  <el-table-column prop="ps3" label="状态">
                    <template slot-scope="scope">
                      {{
                        scope.row.early_warning == 1
                          ? "正常"
                          : scope.row.early_warning == 2
                          ? "关注"
                          : "预警"
                      }}
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
        <div class="status-cgq">
          <div class="trapezoid"></div>
          <span class="text-title">运行状态总览</span>
          <div class="status-z">
            <div class="item-z" v-for="item in statusList" :key="item.type">
              <template v-if="item.type == 'wd'">
                <el-image
                  :src="getImage(item.type, temperatureInfo.early_warning || 1)"
                  class="image-class"
                />
                <div class="text-type">{{ item.remark }}温度监测</div>
                <div class="text-type">
                  当前温度：{{ temperatureInfo.number}}℃
                </div>
              </template>
              <template v-if="item.type == 'jf'">
                <el-image
                  :src="
                    getImage(item.type, partialDischargeInfo.early_warning || 1)
                  "
                  class="image-class"
                />
                <div class="text-type">{{ item.remark }}局放监测</div>
                <div class="text-type" v-if="item.type == 'jf'">
                幅值：{{ partialDischargeInfo.electricity_max  }}dB/{{
                  partialDischargeInfo.sensor_type 
                }}
              </div>
                <!-- <div class="text-type">幅值：{{ 9.6 }}dB/{{ "uhf" }}</div> -->
              </template>
              <template v-if="item.type == 'zd'">
                <el-image
                  :src="getImage(item.type, vibrationInfo.early_warning || 1)"
                  class="image-class"
                />
                <div class="text-type">{{ item.remark }}振动监测</div>
                <div class="text-type">
                  <!-- 最大振幅：{{ vibrationInfo.vibration || "-" }} -->
                  最大振幅：{{ "-" }}
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="yujing-jisuan">
          <div class="title">
            <div class="img">
              <el-image style="width: 60%; height: auto" :src="titleSrc" />
            </div>
            <div class="text">
              <span>当前告警</span>
              <hr />
            </div>
          </div>
          <div class="byq-content">
            <div class="d-flex">
              <div class="table-warning">
                <div class="info" style="margin-left: 40px">
                  <div class="d-flex texts">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1
                  </div>
                  <div class="d-flex"><el-image :src="lightSrc" /></div>
                  <div class="d-flex texts">&nbsp;&nbsp;当前告警</div>
                </div>
                <div class="info" style="margin-top: 40px; margin-left: 40px">
                  <div class="d-flex texts">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;xx处
                  </div>
                  <div class="d-flex"><el-image :src="lightSrc" /></div>
                  <div class="d-flex texts">&nbsp;&nbsp;&nbsp;告警位置</div>
                </div>
              </div>
              <div style="margin-top: 21%">
                <el-button
                  type="warning"
                  style="
                    height: 30px;
                    font-size: 16px;
                    color: #000;
                    cursor: pointer;
                    line-height: 5px;
                  "
                  @click="clickEvent"
                >
                  隐患导入
                </el-button>
              </div>
              <div class="image-warning">
                <div class="project-manage" :style="{ height: tableWHeight }">
                  <Chart
                    :cdata="yibiaoData"
                    :heightX="tableWHeight"
                    :id="'yibiaochart'"
                  />
                </div>
              </div>
            </div>
            <div class="d-flex margin-t">
              <el-table
                border
                height="140px"
                header-align="center"
                :data="tableData"
                style="width: 100%"
              >
                <el-table-column prop="ps1" label="部件名称"> </el-table-column>
                <el-table-column prop="ps2" label="本体"> </el-table-column>
                <el-table-column prop="ps3" label="套管"> </el-table-column>
                <el-table-column prop="ps4" label="分接开关"> </el-table-column>
                <el-table-column prop="ps5" label="冷却系统"> </el-table-column>
                <el-table-column prop="ps6" label="电量保护"> </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-content">
        <div class="wd">
          <div class="title">
            <div class="img">
              <el-image style="width: 60%; height: auto" :src="titleSrc" />
            </div>
            <div class="text">
              <span>温度数据</span>
              <hr />
            </div>
          </div>
          <div class="byq-content">
            <div class="top">
              <div class="d-flex">
                <div class="text-btn">传感器位置：</div>
                <template v-if="temData[0] && temData[0].sensor.length > 0">
                  <el-button
                    v-for="(item, index) in temData[0].sensor"
                    :key="index"
                    @click="choosePosition(index, 'tem')"
                    :style="{
                    background:
                    curClickBtn == `tem-${index}`
                        ? '#00c1de'
                        : '',
                  }"
                    class="button-3d-3"
                    >{{ item.sensor_position_transForm }}</el-button
                  >
                </template>
              </div>
              <div class="chart">
                <chart-wd
                  :id="'wd_data'"
                  :cdata="temOptionsData"
                  :heightX="heightH"
                />
              </div>
              <div class="d-flex">
                <el-button
                  @click="detailHistoryClick('tem')"
                  class="button-3d-3"
                  >详细历史数据</el-button
                >
                <!-- <el-button class="button-3d-3">横向分析</el-button> -->
              </div>
              <div class="d-flex">
                <div class="table">
                  <el-table
                    class="table-layout"
                    border
                    header-align="center"
                    :height="tableWHeight"
                    :data="temTable"
                    style="width: 100%; margin: 0 auto"
                  >
                    <el-table-column prop="establish_time" label="时间">
                    </el-table-column>
                    <el-table-column prop="number" label="温度值(℃)">
                    </el-table-column>
                    <el-table-column prop="early_warning" label="状态">
                      <template slot-scope="scope">
                        {{
                          scope.row.early_warning == 1
                            ? "正常"
                            : scope.row.early_warning == 2
                            ? "关注"
                            : "预警"
                        }}
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div class="image">
                  <div class="project-manage" :style="{ height: tableWHeight }">
                    <div class="tem-font">最热点温度：23℃</div>

                    <el-image
                      :src="wdurl"
                      class="adaptive-image"
                      fit="cover"
                      :preview-src-list="wdurlList"
                    ></el-image>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="jf">
          <div class="title">
            <div class="img">
              <el-image style="width: 60%; height: auto" :src="titleSrc" />
            </div>
            <div class="text">
              <span>局放数据</span>
              <hr />
            </div>
          </div>
          <div class="byq-content">
            <div class="top">
              <div class="d-flex">
                <div class="text-btn">传感器位置：</div>
                <el-button
                  v-for="(item, index) in uhfData.sensor"
                  v-if="uhfData.sensor.length > 0"
                  :key="index"
                  @click="choosePosition(index, 'pd')"
                  :style="{
                    background:
                    curClickBtn == `pd-${index}`
                        ? '#00c1de'
                        : '',
                  }"
                  class="button-3d-3"
                  >{{ item.sensor_position_transForm }}</el-button
                >
              </div>
              <div class="chart">
                <chart-wd
                  :id="'jf_data'"
                  :cdata="pduhfOptionsData"
                  :heightX="heightH"
                />
              </div>
              <div class="d-flex">
                <el-button class="button-3d-3" @click="detailHistoryClick('pd')"
                  >详细历史数据</el-button
                >
                <!-- <el-button class="button-3d-3">横向分析</el-button> -->
              </div>
              <div class="d-flex">
                <div class="table-x">
                  <el-table
                    class="table-layout"
                    border
                    header-align="center"
                    :height="tableWHeight"
                    :data="pdTableData"
                    @cell-click="handleCellClick"
                    style="width: 100%; margin: 0 auto"
                  >
                    <el-table-column prop="sensor_type" label="类型">
                    </el-table-column>
                    <el-table-column prop="sensor_position" label="位置">
                    </el-table-column>
                    <el-table-column prop="establish_time" label="时间">
                    </el-table-column>
                    <el-table-column prop="early_warning" label="状态">
                      <template slot-scope="scope">
                        {{
                          scope.row.early_warning == 1
                            ? "正常"
                            : scope.row.early_warning == 2
                            ? "关注"
                            : "预警"
                        }}
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div class="image-x">
                  <div class="prpd-content">
                    <Chart
                      :cdata="cdata"
                      :heightX="tableWHeight"
                      :id="'prpd_chart'"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="zd">
          <div class="title">
            <div class="img">
              <el-image style="width: 60%; height: auto" :src="titleSrc" />
            </div>
            <div class="text">
              <span>振动数据</span>
              <hr />
            </div>
          </div>
          <div class="byq-content">
            <div class="top">
              <div class="d-flex">
                <el-button
                  class="button-3d-3"
                  @click="detailHistoryClick('vib')"
                  >详细历史数据</el-button
                >
              </div>
              <div
                class="flex-wrap"
                v-if="vibData[0] && vibData[0].sensor.length > 0"
              >
              <Chart3DLine :lineData="lineData" :id="'3d_line'" height="250px"></Chart3DLine>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="温度历史数据"
      :visible.sync="temDialogVisiable"
      @close="dialogClose"
      width="60%"
      custom-class="custom-dialog"
    >
      <div class="d-flex">
        <div class="text-btn">传感器位置：</div>
        <template v-if="temData[0] && temData[0].sensor.length > 0">
          <el-button
            v-for="(item, index) in temData[0].sensor"
            :key="index"
            @click="choosePosition(index, 'tem')"
            class="button-3d-3"
            >{{ item.sensor_position_transForm }}</el-button
          >
        </template>
      </div>
      <div class="chart">
        <chart-wd :id="'wd_his_data'" :cdata="temOptionsData" height="250px" />
      </div>

      <div class="d-flex">
        <div class="table">
          <el-table
            class="table-layout"
            border
            header-align="center"
            :height="400"
            :data="temTable"
            style="width: 100%; margin: 0 auto"
          >
            <el-table-column prop="establish_time" label="时间">
            </el-table-column>
            <el-table-column prop="number" label="温度值(℃)"> </el-table-column>
            <el-table-column prop="early_warning" label="状态">
              <template slot-scope="scope">
                {{
                  scope.row.early_warning == 1
                    ? "正常"
                    : scope.row.early_warning == 2
                    ? "关注"
                    : "预警"
                }}
              </template>
            </el-table-column>
          </el-table>
          <el-pagination v-if="temData[0]" @current-change="handleCurrentChange" background layout="prev, pager, next" :page-count="temData[0].page_count || 1">
          </el-pagination> 
        </div>
        <div class="image">
          <div class="project-manage" :style="{ height: tableWHeight }">
            <div class="tem-font">最热点温度：23℃</div>
            <el-image
              :src="wdurl"
              class="adaptive-image"
              fit="cover"
              :preview-src-list="wdurlList"
            ></el-image>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="局放历史数据"
      :visible.sync="pdDialogVisiable"
      width="60%"
      @close="dialogClose"
      custom-class="custom-dialog"
    >
      <div class="byq-content">
        <div class="top">
          <div class="d-flex">
            <div class="text-btn">传感器位置：</div>

            <el-button
              v-for="(item, index) in uhfData.sensor"
              v-if="uhfData.sensor.length > 0"
              :key="index"
              @click="choosePosition(index, 'pd')"
              class="button-3d-3"
              >{{ item.sensor_position_transForm }}</el-button
            >
          </div>
          <div class="chart">
            <chart-wd
              :id="'jf_data'"
              :cdata="pduhfOptionsData"
              :heightX="heightH"
            />
            <chart-wd
              :id="'ae_data'"
              :cdata="pdaeOptionsData"
              :heightX="heightH"
            />
          </div>
          <div class="d-flex">
            <div class="table-x">
              <el-table
                border
                header-align="center"
                :height="300"
                :data="pdTableData"
                @cell-click="handleCellClick"
                style="width: 100%; margin: 0 auto"
              >
                <el-table-column prop="sensor_type" label="类型">
                </el-table-column>
                <el-table-column prop="sensor_position" label="位置">
                </el-table-column>
                <el-table-column prop="early_warning" label="状态">
                  <template slot-scope="scope">
                    {{
                      scope.row.early_warning == 1
                        ? "正常"
                        : scope.row.early_warning == 2
                        ? "关注"
                        : "预警"
                    }}
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination v-if="pdData[0]" @current-change="handleCurrentChange" background layout="prev, pager, next" :page-count="pdData[0].page_count || 1">
              </el-pagination> 
            </div>
            <div class="image-x">
              <div class="prpd-content">
                <Chart :cdata="cdata" :heightX="350" :id="'prpd_chart_big'" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="振动历史数据"
      :visible.sync="vibDialogVisiable"
      width="60%"
      custom-class="custom-dialog"
    >
      <chart-wd :id="'vib_data0'" :cdata="vibOptionsData0" :height="'300px'" />
      <chart-wd :id="'vib_data1'" :cdata="vibOptionsData1" :height="'300px'" />
      <chart-wd :id="'vib_data2'" :cdata="vibOptionsData2" :height="'300px'" />
      <chart-wd :id="'vib_data3'" :cdata="vibOptionsData3" :height="'300px'" />
      <chart-wd :id="'vib_data4'" :cdata="vibOptionsData4" :height="'300px'" />
      <chart-wd :id="'vib_data5'" :cdata="vibOptionsData5" :height="'300px'" />
    </el-dialog>
  </div>
</template>

<script>
import chartWd from "./echarts.vue";
import Chart from "./echartsprpd.vue"; // 导入prpd组件
import Chart3DLine from "./echarts3dLine.vue"; // 导入prpd组件
import { singlePrpds } from "@/api/index";
import {
  getEquipmentInfo,
  getEquipmentReal,
  getEquipmentHistory,
  sensorPosition,
} from "@/api/service";

export default {
  props: {},
  components: { chartWd, Chart,Chart3DLine },
  computed: {
    uhfData() {
      let uhfData = {};
      this.pdData.forEach((item) => {
        if (item.sensor_type == "uhf") {
          uhfData = item;
        }
      });
      return uhfData;
    },
  },
  data() {
    return {
      visiable: false,
      num: 0,
      sensorPositionData: [], //后端获取的位置中英文转换
      height: "calc(100% - 200px)",
      titleSrc: require("../image/biaoshi.png"),
      byqSrc: require("../image/byq.png"),
      lightSrc: require("../image/light.png"),
      wdurl: require("../image/reli.png"),
      wdurlList: [require("../image/reli.png")],
      tableHeight: 160,
      tableWHeight: 140,
      heightH: 140,
      statusList: [{ type: "wd" }, { type: "jf" }, { type: "zd" }],
      tableData: [],
      temOptionsData: {}, //温度图表数据
      pduhfOptionsData: {}, //局放图表数据
      pdaeOptionsData: {}, //局放图表数据
      vibOptionsData: {}, //振动图表数据
      vibOptionsData0: {}, //振动加速度图表数据
      vibOptionsData1: {}, //振动角速度图表数据
      vibOptionsData2: {}, //振动速度图表数据
      vibOptionsData3: {}, //振动角度图表数据
      vibOptionsData4: {}, //振动位移图表数据
      vibOptionsData5: {}, //振动频率图表数据
      optionsWdData: {
        grid: {
          show: true,
          left: "10%",
          top: "20%",
          right: "10%",
          bottom: "20%",
          backgroundColor: "rgba(255, 255, 67, 0.1)",
          borderColor: "rgba(96, 67, 67, 1)",
        },
        legend: {
          data: ["A相", "B相", "C相"], //图例名称
          right: 10, //调整图例位置
          top: 0, //调整图例位置
          icon: "line", //图例前面的图标形状
          textStyle: {
            //图例文字的样式
            color: "#a1a1a1", //图例文字颜色
            fontSize: 12, //图例文字大小
          },
        },
        title: {
          top: 0,
          left: "center", // left的值为'left', 'center', 'right'
          text: `✯温度参数`,
          // 标题文本样式
          textStyle: {
            color: "#ff0000", // 颜色
            fontStyle: "normal", // 字体风格,'normal','italic','oblique'
            fontWeight: "700", //字体粗细 'normal','bold','bolder','lighter',数值：100-700
            fontFamily: "sans-serif", //字体系列
            fontSize: 14, //字体大小
          },
        },
        xAxis: {
          type: "category",
          data: ["8-10", "8-11", "8-12", "8-13", "8-14", "8-15", "8-16"],
        },
        yAxis: {
          type: "value",
          min: -45,
          max: 45,
          interval: 1000,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#ff0",
            },
          },
        },
        series: [
          {
            name: "温度值",
            data: [20, 32, 30, 24, 18, 20, 22],
            type: "line",
            smooth: true,
          },
        ],
      },
      cdata: {},
      yibiaoData: {
        backgroundColor: "transparent",
        title: {
          show: true,
          text: "过载率",
          x: "50%",
          y: "57%",
          z: 8,
          textAlign: "center",
          textStyle: {
            color: "#f1f7fe",
            fontSize: 18,
            fontWeight: "normal",
          },
        },
        series: [
          {
            name: "",
            type: "gauge",
            // center: ['20%', '50%'],
            radius: "100%",
            splitNumber: 20,
            axisLine: {
              lineStyle: {
                color: [
                  [60 / 100, "#ED1C24"],
                  [1, "#15DCDE"],
                ],
                width: 14,
              },
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            pointer: {
              show: false,
            },
          },
          {
            name: "",
            type: "gauge",
            //  center: ['20%', '50%'],
            radius: "100%",
            min: 0, //最小刻度
            max: 100, //最大刻度
            splitNumber: 10, //刻度数量
            startAngle: 225,
            endAngle: -45,
            axisLine: {
              show: true,
              // 仪表盘刻度线
              lineStyle: {
                width: 0,
                color: [[1, "#FF2"]],
              },
            },
            //仪表盘文字
            axisLabel: {
              show: true,
              color: "#131F3A",
              distance: 0,
            }, //刻度标签。
            axisTick: {
              show: true,
              splitNumber: 20,
              lineStyle: {
                color: "#0C3B5022", //用颜色渐变函数不起作用
                width: 2,
              },
              length: -8,
            }, //刻度样式
            splitLine: {
              show: true,
              length: -20,
              lineStyle: {
                color: "#458EFD11", //用颜色渐变函数不起作用
              },
            }, //分隔线样式
            detail: {
              show: false,
            },
            pointer: {
              show: false,
            },
          },
          /*内部*/
          {
            type: "pie",
            radius: ["0", "70%"],
            center: ["50%", "50%"],
            z: 8,
            hoverAnimation: false,
            data: [
              {
                name: "",
                value: 60,
                itemStyle: {
                  normal: {
                    color: "#131F3A",
                  },
                },
                label: {
                  normal: {
                    formatter: function (params) {
                      return params.value + "%";
                    },
                    color: "#FFFFFF",
                    fontSize: 30,
                    fontWeight: "bold",
                    position: "center",
                    show: true,
                  },
                },
                labelLine: {
                  show: false,
                },
              },
            ],
          },
          /*外一层*/
          {
            type: "pie",
            radius: "80%",
            startAngle: 220,
            endAngle: -40,
            hoverAnimation: false,
            center: ["50%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: 1,
                itemStyle: {
                  normal: {
                    color: "#4DBFE4",
                  },
                },
              },
            ],
          },
          //外二层圈
          {
            type: "pie",
            radius: "60%",
            center: ["50%", "50%"],
            avoidLabelOverlap: false,
            z: 0,
            hoverAnimation: false,
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: 1,
                itemStyle: {
                  normal: {
                    color: "#e3edf8",
                  },
                },
              },
            ],
          },
        ],
      },
      realData: [],
      equipmentInfo: {}, //当前设备信息
      temperatureInfo: {}, //页面上展示的温度信息
      partialDischargeInfo: {}, //页面上展示的局部放电信息
      vibrationInfo: {}, //页面上展示的震动
      temInterval: null,
      vibInterval: null,
      pdInterval: null, //三个定时器
      sensorTable: [], //左上角表格
      //页面下方的三部分的数据
      temData: {}, //温度数据
      temTable: [], //处理后的温度表格数据
      pdData: [], //局部放电数据
      pdTableData: [], //处理后的局部放电表格数据
      vibData: [], //震动数据
      vibTable: [], //处理后的震动表格数据
      equipmentImage: "", //设备图片
      temDialogVisiable: false, //温度历史弹窗
      pdDialogVisiable: false, //局部放电历史弹窗
      vibDialogVisiable: false, //震动历史弹窗
      curPage:1,//分页器数据
      pageCount:5,//页数
      lineData:[],//3D折线图数据
      curClickBtn: '',//当前点击的按钮
    };
  },
  methods: {
    btnChangeColor(type) {
      this.curClickBtn = type
    },
    getImage(type, warningStatus) {
      return require(`../image/${type}-${warningStatus}.png`);
    },
    handleCurrentChange(val) {
      this.curPage = val
      this.getEquipmentHistoryData()
    },
    dialogClose() {
      this.curPage = 1
      this.getEquipmentHistoryData()
    },
    // 获取设备信息
    equipmentInformation(id) {
      getEquipmentInfo(id).then((res) => {
        if (res.code == 200) {
          this.equipmentInfo = res.data;
          let givenTime = new Date(res.data.start_up);
          let currentTime = new Date();
          let timeDifference = currentTime - givenTime;
          this.equipmentInfo.workHours = Math.floor(
            timeDifference / (1000 * 60 * 60)
          );
          this.equipmentInfo.start_up = res.data.start_up.substring(0, 10);
        }
      });
    },
    //获取实时数据
    getEquipmentRealData() {
      let param = {
        test_equipment_id: parseInt(this.$route.query.equipmentId),
      };
      getEquipmentReal(param).then((res) => {
        if (res.code == 200) {
          // 筛选温度，局放，震动数据
          this.equipmentRealData = res.data;
          //处理数据成图表需要的格式
          let tableData = res.data.filter((item) => item.data_line.length > 0);
          this.realData = tableData;
          let dealData = [];

          tableData.map((item) => {
            item.data_line.map((ele) => {
              dealData.push({ ...ele, sensor_type: item.sensor_type });
            });
          });
          this.sensorTable = dealData;
          
          // 下面是页面中间的运行状态
          let temData = res.data.filter((item) => item.sensor_type === "tem");
          
          let vibData = res.data.filter((item) => item.sensor_type === "vibra");
          
          let pdData = res.data.filter(
            (item) => item.sensor_type !== "tem" && item.sensor_type !== "vibra"
          );
          if (temData[0].data_line.length > 0) {
            let temIndex = 0;
            this.temInterval = setInterval(() => {
              // 展示当前项
              this.temperatureInfo = temData[0].data_line[temIndex];
              // 移动到下一项
              temIndex++;
              // 如果到达最后一项，重置为第一项
              if (temIndex >= temData[0].data_line.length) {
                temIndex = 0;
              }
            }, 5000);
          }
          if (vibData[0].data_line.length > 0) {
            let vibIndex = 0;
            this.vibInterval = setInterval(() => {
              // 展示当前项
              this.vibrationInfo = vibData[0].data_line[vibIndex];
              // 移动到下一项
              vibIndex++;
              // 如果到达最后一项，重置为第一项
              if (vibIndex >= vibData[0].data_line.length) {
                vibIndex = 0;
              }
            }, 5000);
          }
          let currentIndex = 0; // 记录当前的传感器索引
          let dataIndex = 0; // 记录当前sensor的数据索引
          this.pdInterval = setInterval(() => {
            let found = false;
            // 查找有数据的传感器
            for (let i = 0; i < pdData.length; i++) {
              const sensor = pdData[currentIndex];
              // 如果当前sensor有数据
              if (sensor.data_line.length > 0) {
                found = true;
                // 输出当前数据
                this.partialDischargeInfo = {
                  ...sensor.data_line[dataIndex],
                  sensor_type: sensor.sensor_type,
                };
                dataIndex++;
                // 如果当前sensor的数据已经输出完毕，重置dataIndex并跳到下一个sensor
                if (dataIndex >= sensor.data_line.length) {
                  dataIndex = 0;
                  currentIndex = (currentIndex + 1) % pdData.length;
                }
                break;
              }
              // 如果当前sensor没有数据，继续寻找下一个sensor
              currentIndex = (currentIndex + 1) % pdData.length;
            }
          }, 5000);
        }
      });
    },
    //获取位置信息
    async getSensorPosition() {
      await sensorPosition().then((res) => {
        if (res.code == 200) {
          this.sensorPositionData = res.data;
        }
      });
    },
    //获取历史数据
    getEquipmentHistoryData() {
      let param = {
        test_suffer_equipment_id: parseInt(this.$route.query.equipmentId),
        page: this.curPage,
      };
      getEquipmentHistory(param).then((res) => {
        if (res.code == 200) {
          let temData = res.data.filter((item) => item.sensor_type === "tem");
          let pdData = res.data.filter(
            (item) => item.sensor_type !== "tem" && item.sensor_type !== "vibra"
          );
          let vibData = res.data.filter((item) => item.sensor_type === "vibra");
          this.temData = temData;
          this.pdData = pdData;
          if (vibData[0].sensor.length > 0) {
            vibData[0].sensor[0].data_PDD[0].vibration = JSON.parse(
              vibData[0].sensor[0].data_PDD[0].vibration
            );
          }
          this.vibData = vibData;

          this.dealTemChartData(temData, 0);
          this.dealPdChartData(pdData, 0);
          this.dealVibChartData(vibData, 0);
          
        }
      });
    },
    //位置按钮
    choosePosition(index, type) {
      switch (type) {
        case "tem":
          this.dealTemChartData(this.temData, index);
          this.btnChangeColor(`tem-${index}`);
          break;
        case "vibra":
          break;
        case "pd":
          this.dealPdChartData(this.pdData, index);
          this.btnChangeColor(`pd-${index}`);
          break;

        default:
          break;
      }
    },
    dealTemChartData(temData, curIndex) {
      // 找出位置
      if (temData[0].sensor.length == 0) return;
      //转换位置英文为中文
      temData[0].sensor.map((item, index) => {
        let sensorTrance = this.sensorPositionData.filter(
          (ele) => ele.tripartite_parameters_explain == item.sensor_position
        );
        item.sensor_position_transForm = sensorTrance[0].tripartite_parameters;
      });
      //筛选出图表上面的x轴时间数据和y轴的温度数据
      let xAxis = [];
      let yAxis = [];
      let temTable = [];
      temData[0].sensor[curIndex].data_PDD.map((ele, index) => {
        xAxis.push(ele.establish_time);
        yAxis.push(ele.number);
        temTable.push(ele);
      });
      this.temTable = temTable;
      this.temOptionsData = {
        ...this.optionsWdData,
        xAxis: {
          type: "category",
          data: xAxis,
        },
        yAxis: {
          min: -45,
          max: 45,
        },
        series: [
          {
            name: "温度",
            data: yAxis,
            type: "line",
            smooth: true,
          },
        ],
      };
    },
    dealPdChartData(pdData, curIndex) {
      // 筛选出UHF数据，筛选出最大幅值，平均值，脉冲数三条线
      let uhfChartAxis = {
        electricity_max: [], //
        pulse_number: [],
        average: [],
        establish_time: [],
      };
      let aeyChartAxis = {
        electricity_max: [],
        pulse_number: [],
        average: [],
        establish_time: [],
      };
      let pdTableData = [];

      let uhfData = pdData.filter((item) => item.sensor_type === "uhf");

      let aeData = pdData.filter((item) => item.sensor_type === "ae");
      if (uhfData[0].sensor.length != 0) {
        uhfData[0].sensor.map((item, index) => {
          let sensorTrance = this.sensorPositionData.filter(
            (ele) => ele.tripartite_parameters_explain == item.sensor_position
          );
          item.sensor_position_transForm =
            sensorTrance[0].tripartite_parameters;
        });
        uhfData[0].sensor[curIndex].data_PDD.map((item, index) => {
          uhfChartAxis.electricity_max.push(item.electricity_max);
          uhfChartAxis.pulse_number.push(item.pulse_number);
          uhfChartAxis.average.push(item.average);
          uhfChartAxis.establish_time.push(item.establish_time);
          pdTableData.push({
            ...item,
            sensor_type: "UHF",
            sensor_position:
              uhfData[0].sensor[curIndex].sensor_position_transForm,
          });
        });

        this.pduhfOptionsData = {
          ...this.optionsWdData,
          xAxis: {
            type: "category",
            data: uhfChartAxis.establish_time,
          },
          yAxis: {
          min: 0,
          max: 61,
        },
          title: {
            top: 0,
            left: "center", // left的值为'left', 'center', 'right'
            text: `UHF实时数据`,
            // 标题文本样式
            textStyle: {
              color: "#ff0000", // 颜色
              fontStyle: "normal", // 字体风格,'normal','italic','oblique'
              fontWeight: "700", //字体粗细 'normal','bold','bolder','lighter',数值：100-700
              fontFamily: "sans-serif", //字体系列
              fontSize: 14, //字体大小
            },
          },
          series: [
            {
              name: "最大幅值",
              data: uhfChartAxis.electricity_max,
              type: "line",
              smooth: true,
            },
            {
              name: "最大平均幅值",
              data: uhfChartAxis.average,
              type: "line",
              smooth: true,
            },
            {
              name: "脉冲数",
              data: uhfChartAxis.pulse_number,
              type: "line",
              smooth: true,
            },
          ],
        };
      }

      if (aeData[0].sensor.length != 0) {
        aeData[0].sensor.map((item, index) => {
          let sensorTrance = this.sensorPositionData.filter(
            (ele) => ele.tripartite_parameters_explain == item.sensor_position
          );
          item.sensor_position_transForm =
            sensorTrance[0].tripartite_parameters;
        });

        aeData[0].sensor[curIndex].data_PDD.map((item, index) => {
          aeyChartAxis.electricity_max.push(item.electricity_max);
          aeyChartAxis.pulse_number.push(item.pulse_number);
          aeyChartAxis.average.push(item.average);
          aeyChartAxis.establish_time.push(item.establish_time);
          pdTableData.push({
            ...item,
            sensor_type: "ae",
            sensor_position: aeData[0].sensor[curIndex].sensor_position_transForm,
          });
        });
        this.pdaeOptionsData = {
          ...this.optionsWdData,
          xAxis: {
            type: "category",
            data: uhfChartAxis.establish_time,
          },
          yAxis: {
           min: 0,
           max: 61
          },
          title: {
            top: 0,
            left: "center", // left的值为'left', 'center', 'right'
            text: `ae实时数据`,
            // 标题文本样式
            textStyle: {
              color: "#ff0000", // 颜色
              fontStyle: "normal", // 字体风格,'normal','italic','oblique'
              fontWeight: "700", //字体粗细 'normal','bold','bolder','lighter',数值：100-700
              fontFamily: "sans-serif", //字体系列
              fontSize: 14, //字体大小
            },
          },
          series: [
            {
              name: "最大幅值",
              data: aeyChartAxis.electricity_max,
              type: "line",
              smooth: true,
            },
            {
              name: "最大平均幅值",
              data: aeyChartAxis.average,
              type: "line",
              smooth: true,
            },
            {
              name: "脉冲数",
              data: aeyChartAxis.pulse_number,
              type: "line",
              smooth: true,
            },
          ],
        };
      }
      this.pdTableData = pdTableData;
      console.log(this.pdTableData,'处理后的局放表格数据');
      
    },
    //局放部分表格行点击
    handleCellClick(row) {
      let array = JSON.parse(row.prpd_data);
      // 处理数组
      const maxIndex = 360; // 我们希望索引范围是 0-359
      const elementsPerGroup = 128;
      const step = maxIndex / elementsPerGroup; // 计算每个元素的步长
      let result = [];
      for (let i = 0; i < array.length; i += elementsPerGroup) {
        // 切割每一组128个元素
        const chunk = array
          .slice(i, i + elementsPerGroup)
          .map((value, index) => {
            // 计算新的索引，将索引平分到0-359范围内
            const newIndex = Math.floor(index * step);
            return [newIndex, value];
          });

        // 合并每一组的数据到结果数组中
        result = result.concat(chunk);
      }
      let xAxisData = [];
      for (let index = 0; index < 359; index++) {
        xAxisData.push(index);
      }
      this.cdata = {
        grid: {
          show: true,
          left: "20%",
          top: "15%",
          right: "10%",
          bottom: "15%",
          backgroundColor: "rgba(255, 255, 67, 0.1)",
          borderColor: "rgba(96, 67, 67, 1)",
        },
        title: {
          top: 0,
          left: "center", // left的值为'left', 'center', 'right'
          text: "prpd图谱",
          // 标题文本样式
          textStyle: {
            color: "#fff", // 颜色
            fontStyle: "normal", // 字体风格,'normal','italic','oblique'
            fontWeight: "200", //字体粗细 'normal','bold','bolder','lighter',数值：100-700
            fontFamily: "sans-serif", //字体系列
            fontSize: 14, //字体大小
          },
        },
        xAxis: {
          type: "category",
          minorTick: {
            show: false,
          },
          minorSplitLine: {
            show: false,
          },
          axisLabel: {
            align: "center",
          },
          data: xAxisData,
        },
        yAxis: {
          name: "幅\n值\n（mV）",
          type: "value",
          nameLocation: "bottom",
          min: 0,
          max: 255,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
          extraCssText: "max-height: 100px; overflow-y: auto;",
        },
        series: [
          {
            name: "scatter",
            type: "scatter",
            symbolSize: 1,
            color: "rgba(255,201,14)",
            data: result,
          },
        ],
      };
    },
    dealVibChartData(vibData, curIndex) {
      // 找出位置
      if (vibData[0].sensor.length == 0) return;
      //筛选出图表上面的x轴时间数据和y轴的温度数据
      let xAxis = [];
      let yAxis = [];
      let vibTable = [];
      vibData[curIndex].sensor.map((item, index) => {
        item.data_PDD.map((ele) => {
          xAxis.push(ele.establish_time);
          yAxis.push(ele.number);
          vibTable.push(ele);
        });
      });
      this.vibTable = vibTable;
      this.dealVib3DLineData(vibTable)
    },
    //处理3D图数据
    dealVib3DLineData(vibTable){
      let lineData = [
            { name: "加速度", value: [] },
            { name: "角速度", value: [] },
            { name: "振动速度", value: [] },
            { name: "振动角度", value: [] },
            { name: "振动位移", value: [] },
            { name: "振动频率", value: [] },
          ];
          let dealVibration = []
          vibTable.forEach((item) => {
            if (typeof item.vibration == "string") {
              item.vibration = JSON.parse(item.vibration);
            }
            item.vibration.forEach((ele, index) => {
            if (index % 3 == 0) {
              dealVibration.push(item.vibration.slice(index, index + 3));
            }
            if (index == 17) {
              item.dealVibration = dealVibration;
              dealVibration = [];
            }
            })
          });
          vibTable.forEach((item) => {
          item.dealVibration.forEach((ele,index) => {
            lineData[index].value.push(ele)
          })
        })
        this.lineData = lineData
    },
    detailHistoryClick(type) {
      switch (type) {
        case "tem":
          this.temDialogVisiable = true;

          break;
        case "pd":
          this.pdDialogVisiable = true;

          break;
        case "vib":
          let xAxis = [];
          let legend = [];
          let vibraData = [
            { name: "加速度X", value: [] },
            { name: "加速度Y", value: [] },
            { name: "加速度Z", value: [] },
            { name: "角速度X", value: [] },
            { name: "角速度Y", value: [] },
            { name: "角速度Z", value: [] },
            { name: "X轴振动速度", value: [] },
            { name: "Y轴振动速度", value: [] },
            { name: "Z轴振动速度", value: [] },
            { name: "X轴振动角度", value: [] },
            { name: "Y轴振动角度", value: [] },
            { name: "Z轴振动角度", value: [] },
            { name: "X轴振动位移", value: [] },
            { name: "Y轴振动位移", value: [] },
            { name: "Z轴振动位移", value: [] },
            { name: "X轴振动频率", value: [] },
            { name: "Y轴振动频率", value: [] },
            { name: "Z轴振动频率", value: [] },
          ];
          let yAxis = [
            { title: "加速度", name: "g" },
            { title: "角速度", name: "o/s" },
            { title: "振动速度", name: "㎜/s" },
            { title: "振动角度", name: "°" },
            { title: "振动位移", name: "μm" },
            { title: "振动频率", name: "" },
          ];
          let series = [];
          this.vibDialogVisiable = true;
          this.vibTable.forEach((item) => {
            if (typeof item.vibration == "string") {
              item.vibration = JSON.parse(item.vibration);
            }
            xAxis.push(item.establish_time);
            vibraData.map((ele, index) => {
              ele.value.push(item.vibration[index]);
            });
          });
          vibraData.forEach((item) => {
            series.push({
              name: item.name,
              data: item.value,
              type: "line",
              smooth: true,
            });
            legend.push(item.name);
          });
          let legendResult = [];
          for (let i = 0; i < legend.length; i += 3) {
            legendResult.push(legend.slice(i, i + 3));
          }
          let seriesResult = [];
          for (let i = 0; i < series.length; i += 3) {
            seriesResult.push(series.slice(i, i + 3));
          }
          for (let index = 0; index < legendResult.length; index++) {
            this[`vibOptionsData${index}`] = {
              ...this.optionsWdData,
              xAxis: {
                type: "category",
                data: xAxis,
                name: "时间",
              },
              yAxis: {
                type: "value",
                name: yAxis[index].name, // 设置Y轴名称
              },
              legend: {
                right: "10%", // 右侧边距
                top: "10%", // 顶部边距
                data: legendResult[index], // 每条线的名称
              },
              title: {
                top: 0,
                left: "center", // left的值为'left', 'center', 'right'
                text: yAxis[index].title,
                // 标题文本样式
                textStyle: {
                  color: "#fff", // 颜色
                  fontStyle: "normal", // 字体风格,'normal','italic','oblique'
                  fontWeight: "200", //字体粗细 'normal','bold','bolder','lighter',数值：100-700
                  fontFamily: "sans-serif", //字体系列
                  fontSize: 14, //字体大小
                },
              },
              series: seriesResult[index],
            };
          }
          break;

        default:
          break;
      }
    },

    clickEvent() {},
    handleResize() {
      const windowHeight = window.innerHeight;
      const extraSpace = 950; // 额外的空间，单位px
      this.tableHeight = Math.max(160, windowHeight - extraSpace);
      this.tableWHeight = Math.max(150, windowHeight - extraSpace);
    },
    setData(query) {
      // 数据进度值

      // API_V1.0/sensor/prpd_orientation/1/100  <sensor_id>/<偏移量>
      singlePrpds(query)
        .then((data) => {
          let series = [];
          // 正弦曲线
          const xAxisData = [];
          const dataSin = [];
          // 当页面初始化需要展示正弦的时候就使用 ω 不需要展示则直接使用 Max
          // Max这里表示最大值
          const MAX = data.data.max_amplitude;
          // const ω = 0.03;
          const ω = MAX;
          for (let i = 0; i < 360; i += 1) {
            xAxisData.push(i);
            // y=Asin(ωx+φ) + k
            // dataSin.push([i, MAX * Math.sin((2 * Math.PI * i) / 360)]);
            dataSin.push([i, MAX * Math.sin((2 * Math.PI * i) / 360)]);
          }

          //第二步构造数据
          let data1 = []; //数据区域缩放组件
          data1 = data.data.PRPD;
          series = [
            {
              name: "正弦曲线",
              type: "line",
              smooth: true,
              showSymbol: false,
              clip: true,
              data: dataSin,
              lineStyle: {
                color: "#2bf9d6",
                width: 3,
              },
            },
            {
              name: "scatter",
              type: "scatter",
              symbolSize: 4,
              color: "rgba(255,201,14)",
              data: data1,
            },
          ];
          this.cdata = {
            grid: {
              show: true,
              left: "20%",
              top: "15%",
              right: "10%",
              bottom: "15%",
              backgroundColor: "rgba(255, 255, 67, 0.1)",
              borderColor: "rgba(96, 67, 67, 1)",
            },
            title: {
              top: 0,
              left: "center", // left的值为'left', 'center', 'right'
              text: "prpd图谱",
              // 标题文本样式
              textStyle: {
                color: "#fff", // 颜色
                fontStyle: "normal", // 字体风格,'normal','italic','oblique'
                fontWeight: "200", //字体粗细 'normal','bold','bolder','lighter',数值：100-700
                fontFamily: "sans-serif", //字体系列
                fontSize: 14, //字体大小
              },
            },
            xAxis: {
              type: "category",
              minorTick: {
                show: false,
              },
              minorSplitLine: {
                show: false,
              },
              axisLabel: {
                align: "center",
              },
              data: xAxisData,
            },
            yAxis: {
              name: "幅\n值\n（mV）",
              type: "value",
              nameLocation: "bottom",
              min: 0,
              max: 255,
            },
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
                crossStyle: {
                  color: "#999",
                },
              },
            },
            series: [
              {
                name: "正弦曲线",
                type: "line",
                smooth: true,
                showSymbol: false,
                clip: true,
                data: dataSin,
                lineStyle: {
                  color: "#2bf9d6",
                  width: 3,
                },
              },
              {
                name: "scatter",
                type: "scatter",
                symbolSize: 4,
                color: "rgba(255,201,14)",
                data: data1,
              },
            ],
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //局放 温度 振动按钮点击
    changeRealTableData(type) {
      this.btnChangeColor(type);
      let tableData = [];
      console.log(this.realData, "this.realData");
      if (type == "pd") {
        this.realData.map((item) => {
          if (item.sensor_type !== "tem" && item.sensor_type !== "vibra") {
            item.data_line.map((ele) => {
              tableData.push({ ...ele });
            });
          }
        });
      } else {
        this.realData.map((item) => {
          if (item.sensor_type == type) {
            item.data_line.map((ele) => {
              tableData.push({ ...ele });
            });
          }
        });
      }
      this.sensorTable = tableData;
    },
  },
  async mounted() {
    window.addEventListener("resize", this.handleResize);
    await this.getSensorPosition();
    this.equipmentInformation(this.$route.query.equipmentId);
    this.getEquipmentRealData();
    this.getEquipmentHistoryData();
    switch (this.$route.query.equipmentType) {
      case "byq":
        this.equipmentImage = require("../image/byq.png");
        break;
      case "kgg":
        this.equipmentImage = require("../image/kgg.png");
        break;
      case "dl":
        this.equipmentImage = require("../image/dl.png");
        break;
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
    clearInterval(this.temInterval);
    clearInterval(this.vibInterval);
    clearInterval(this.pdInterval);
  },
};
</script>

<style lang="scss" scoped>
/* Your styles here */
// @import "../../css/detailInfo.scss";
.analysis-content {
  margin: 0 10px;
  height: calc(100vh - 160px);
  overflow-y: scroll;
  color: rgb(184, 171, 171);
  .title {
    display: flex;
  }

  .top-bar {
    &::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 10;
      background: #093c77;
    }
  }
  .right-bar {
    position: absolute;
    left: 10px;
    height: 5px;
    width: 100%;
    border-top: 1px solid #2681ff;
    border-left: 2px solid #2681ff;
    background: rgba(55, 126, 255, 0.04);
    border-top-left-radius: 5px;
    transform: skewX(-45deg);
    box-shadow: 0 5px 28px 0 rgba(55, 126, 255, 0.28);
  }

  .left-bar {
    position: absolute;
    left: 0;
    top: 2px;
    height: 50px;
    width: 152px;
    border-right: 1px solid #2681ff;
    border-bottom: 1px solid #2681ff;
    transform: skewX(-45deg);
    border-bottom-right-radius: 5px;
    box-shadow: 0 5px 28px 0 rgba(55, 126, 255, 0.28);
  }

  .return-btn {
    position: absolute;
    left: -31px;
    top: 0;
    width: 180px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    background: #151b22;
    cursor: pointer;
    transform: skewX(-45deg);
    border-bottom-right-radius: 5px;
  }
  .return-text {
    display: inline-block;
    z-index: 11;
    color: #fff;
    font-size: 14px;
    margin-left: 10px;
    transform: skewX(45deg);
    transition: 0.2s;

    &:hover {
      color: #60fafa;
    }
  }

  .return-icon {
    margin-right: 5px;
  }
  .flex-wrap {
    display: flex;
    width: 100%;
    width: auto;
    flex-wrap: wrap;
    .flex-item {
      width: 30%;
      text-align: center;
      white-space: nowrap;
      margin: 0 6px;
      font-size: 16px;
      color: #fff;
      line-height: 40px;
    }
    span {
      color: #e8ae20;
    }
  }
  .d-flex {
    display: flex;
    .zd-table {
      flex: 1;
    }
    .table-warning {
      flex: 1;
    }
    .image-warning {
      flex: 1;
    }
    .table {
      flex: 2;
    }
    .table-x {
      flex: 1;
    }
    .image-x {
      flex: 1;
    }
    .image {
      flex: 1;
      background-repeat: no-repeat;
      padding: 0rem 0rem;
      padding-bottom: 0;
      width: 100%;
      .project-manage {
        width: 100%; /* 容器宽度占满父元素 */
        display: grid; /* 启用Grid布局 */
        place-items: center; /* 简写，同时实现水平和垂直居中 */
        position: relative; /* 如果需要绝对定位子元素 */
      }
      .tem-font {
        position: absolute;
        top: -20px;
        left: 0;
        color: #fff;
        font-size: 15px;
      }
      .adaptive-image {
        /* 这里通常不需要额外的样式，因为fit="cover"会处理图片的填充 */
        width: 90%; /* 确保图片宽度占满容器 */
        /* height不需要设置，因为fit="cover"会自动调整 */
      }
    }
  }
  .margin-t {
    padding-top: 18px;
  }
  .button-3d-1 {
    height: 20px;
    margin-bottom: 5px;
    line-height: 5px;
    position: relative;
    background: rgb(3, 139, 250);
    border: none;
    color: rgb(209, 248, 243);
    font-size: 14px;
    box-shadow: -2px 2px 0 hsl(214, 79%, 60%);
  }
  .button-3d-2 {
    height: 20px;
    background: hsl(16, 100%, 40%);
    line-height: 5px;
    margin-bottom: 5px;
    border: none;
    color: rgb(209, 248, 243);
    box-shadow: -2px 2px 0 hsl(16, 56%, 79%);
  }
  .button-3d-3 {
    height: 20px;
    margin-bottom: 5px;
    line-height: 5px;
    position: relative;
    background: rgb(122, 187, 240);
    border: none;
    color: rgb(10, 49, 44);
    font-size: 14px;
    box-shadow: -2px 2px 0 hsl(215, 25%, 65%);
  }
  .display-align {
    align-items: center;
  }

  .display-justify {
    justify-content: space-between;
  }

  .content-analysis {
    width: 95%;
    margin: 0px auto;

    .top-content {
      width: 100%;
      height: 48%;
      display: grid;
      grid-template-columns: 2fr 2fr 2fr;
      .byq-info {
        margin: 10px 10px 10px 10px;
        .byq-content {
          width: 100%;
          background-color: #142a6869;
          border: 3px solid #0d47a1;
        }
        .top {
          display: flex;
          padding: 10px 10px 0px 10px;
          height: 34%;
          gap: 10px;
          .image {
            width: 139px;
          }
          .center {
            flex: 1;
            flex-wrap: wrap;
            .info {
              margin-top: 10px;
              .texts {
                color: #fff;
                font-size: 18px;
                font-weight: 700;
                text-align: center;
                line-height: 10px;
                margin-left: 10px;
              }
            }
            .block {
              box-sizing: border-box;
              padding: 0 2px;
              border-radius: 10px;
              color: rgb(245, 215, 84);
              font-size: 15px;
              background-color: #0067b9;
              cursor: pointer;
              border: 1px solid rgb(46, 101, 184);
              box-shadow: 0 0 1px #fff, /* 内发光 */ 0 0 1px #fff,
                /* 内发光 */ 0 0 10px #adadad, /* 紫色亮光 */ 0 0 10px #fffefe; /* 外发光 */
            }
            .all-style {
              margin: 5px;
            }
          }
        }
        .bottom {
          height: 65%;
        }
      }
      .status-cgq {
        border: 1px solid #0d47a1;
        margin: 10px 10px 10px 10px;
        background-color: #08122765;
        .trapezoid {
          width: 400px;
          height: 50px;
          background-color: transparent;
          border-left: 100px solid transparent;
          border-right: 100px solid transparent;
          border-bottom: 50px solid #4a5873; /* 梯形的高度 */
          /* 旋转梯形 */
          transform: rotate(180deg);
          /* 如果你想让梯形居中显示，可以添加以下样式 */
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0px auto; /* 上下外边距和水平居中 */
        }
        .text-title {
          font-size: 24px;
          color: #fff;
          font-weight: 800;
          position: relative;
          top: -30px;
          margin-left: 39%;
          line-height: 20px;
        }
        .status-z {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          .item-z {
            margin: 20px;
          }
          .image-class {
            width: 90%;
          }
          .text-type {
            font-size: 18px;
            font-weight: 800;
            color: #fff;
            margin-top: 20px;
            text-align: center;
          }
        }
      }
      .yujing-jisuan {
        padding: 20px;
        .byq-content {
          width: 100%;
          height: 85%;
          background-color: #142a6869;
          border: 3px solid #0d47a1;
          .info {
            margin-top: 10px;
            .texts {
              color: #fff;
              font-size: 18px;
              font-weight: 700;
              text-align: center;
              line-height: 10px;
              margin-left: 10px;
            }
          }
        }
      }
    }
    .bottom-content {
      width: 100%;
      height: 48%;
      display: grid;
      // margin-top: 4%;
      grid-template-columns: 2fr 2fr 2fr;
      .wd {
        padding: 20px;
        .byq-content {
          width: 100%;
          height: 88%;
          background-color: #142a6869;
          border: 3px solid #0d47a1;
          .top {
            padding: 10px 10px 0px 10px;
            height: 100%;
            .chart {
              width: 100%;
            }
          }
        }
      }
      .jf {
        padding: 20px;
        .byq-content {
          width: 100%;
          height: 88%;
          background-color: #142a6869;
          border: 3px solid #0d47a1;
          .top {
            padding: 10px 10px 0px 10px;
            height: 100%;
            .chart {
              width: 100%;
            }
          }
        }
      }
      .zd {
        padding: 20px;
        .byq-content {
          width: 100%;
          height: 88%;
          background-color: #142a6869;
          border: 3px solid #0d47a1;
          .top {
            padding: 10px 10px 0px 10px;
            height: 100%;
            .chart {
              width: 100%;
            }
          }
        }
      }
    }
    .text {
      width: calc(100% - 68px);
      font-size: 24px;
      color: #28d1dd;
      margin: auto 0;
      margin-top: 0px;

      hr {
        border: none; /* 移除默认的边框样式 */
        height: 3px; /* 设置高度 */
        background-color: #2fcbf1; /* 设置背景颜色即为 <hr> 的颜色 */
        width: 100%; /* 你可以根据需要设置宽度 */
        margin-top: 5px;
      }
    }
  }

  /deep/ .el-table tr {
    background-color: transparent !important;
    text-align: center !important;
  }

  /deep/ .el-table th {
    color: #fff;
    text-align: center;
    background-color: #1f658842 !important;
  }
  /* 隐藏原有的折叠图标 */
  /deep/ .el-table__expand-icon .el-icon svg {
    display: none;
  }

  /deep/ .el-table--border,
  .el-table--group {
    border: 1px solid #555f64;
  }
  /deep/ .el-table .el-table__cell {
    padding: 5px 0;
    min-width: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    vertical-align: middle;
    position: relative;
    text-align: center;
  }

  // 设置输入框的高度
  /deep/ .el-input__inner {
    height: 10px;
    line-height: 12px;
    color: #fff;
    background-color: #85c1fa52;
  }

  /deep/ .el-input-number__increase {
    height: 12px;
    line-height: 12px;
    color: #fff;
    background-color: #85c1fa52;
  }

  /deep/ .el-input-number__increase {
    top: 4px !important;
  }

  /deep/ .el-input-number__decrease {
    height: 12px;
    line-height: 12px;
    color: #fff;
    background-color: #85c1fa52;
  }
  /deep/ .el-table {
    color: #fff;
    background-color: #3a6d9c54;
  }
  /deep/ .el-table td,
  .el-table th.is-leaf {
    border-bottom: 1px solid #74cdface;
    background-color: transparent;
  }

  /deep/ .el-table--border::after,
  .el-table--group::after,
  .el-table::before {
    content: "";
    position: absolute;
    background-color: #5bb3dfa2;
    z-index: 1;
  }

  /deep/ .el-table--border th,
  .el-table__fixed-right-patch {
    border-bottom: 1px solid #5bb3dfa2;
  }

  /deep/.el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: rgba(4, 130, 255, 0.87); //颜色必须是rgb
  }
}
.text-btn {
  font-size: 16px;
  color: #fff;
}
::v-deep .custom-dialog {
  // min-height: 600px;
  background-color: #062b7cc2;
  border: 1px solid #409eff;
}
::v-deep .el-descriptions .is-bordered .el-descriptions-item__cell {
  border: 1px solid #409eff;
}
::v-deep .el-dialog__title {
  color: #fff;
}
::v-deep .el-descriptions__title {
  color: #fff;
}

::v-deep .el-descriptions-item__content {
  min-width: 100px;
  color: #fff;
}
::v-deep .el-descriptions__body {
  background-color: transparent;
}
::v-deep .el-descriptions-item__label.is-bordered-label {
  background-color: transparent;
  color: #fff;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #08C0B9;
  color: transparent;
  color: #FFF;

}

/deep/ .el-pagination.is-background .el-pager li.active {
  cursor: default;
}

/deep/ .el-pagination.is-background .el-pager li:hover {
  color: #08C0B9;
  color: #FFF;

}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #08C0B9;
  color: #FFF;

}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  background-color: #08C0B9;
  color: #FFF;
}
/deep/ .el-pager li {
  background: rgb(10, 87, 129);
}

</style>
