<template>
  <div
    class="chart-container"
    ref="chart"
    style="width: 100%; height: 300px"
  ></div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-gl"; // 引入 echarts-gl 扩展，支持 3D 图表

export default {
  name: "3DChart",
  data() {
    return {};
  },
  props: {
    seriesData: {
      type: Array,
    },
    lineData: {
      type: Array,
    },
  },
  mounted() {
    this.initChart();
  },
  watch: {
    lineData: {
      handler(newData) {
        this.initChart();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    initChart() {
      // 基于准备好的 DOM 容器初始化 echarts 实例
      const chart = echarts.init(this.$refs.chart);
      let series = [];
      // 配置项
      if (this.$props.lineData.length > 0) {
        this.$props.lineData.map((item, index) => {
          series.push({
            name: item.name,
            type: "line3D",
            data: item.value,
            smooth: true,
          });
        });
        console.log(series,'seriesseries');
        
      }
      const option = {
        tooltip: {},
        backgroundColor: "#203e6d",
        visualMap: {
          show: false,
          dimension: 2,
          min: 0,
          max: 30,
          inRange: {
            color: [
              "#313695",
              "#4575b4",
              "#74add1",
              "#abd9e9",
              "#e0f3f8",
              "#ffffbf",
              "#fee090",
              "#fdae61",
              "#f46d43",
              "#d73027",
              "#a50026",
            ],
          },
        },
        xAxis3D: {
          type: "value",
        },
        yAxis3D: {
          type: "value",
        },
        zAxis3D: {
          type: "value",
        },
        grid3D: {
          viewControl: {
            // 控制3D视图
            projection: "orthographic",
          },
        },
        xAxis3D: {
          type: "value",
          nameTextStyle: {
            color: "#ffffff", // X轴名称的颜色
          },
          axisLine: {
            lineStyle: {
              color: "#ffffff", // X轴线的颜色
            },
          },
        },
        yAxis3D: {
          type: "value",
          nameTextStyle: {
            color: "#ffffff", // X轴名称的颜色
          },
          axisLine: {
            lineStyle: {
              color: "#ffffff", // X轴线的颜色
            },
          },
        },
        zAxis3D: {
          type: "value",
          nameTextStyle: {
            color: "#ffffff", // X轴名称的颜色
          },
          axisLine: {
            lineStyle: {
              color: "#ffffff", // X轴线的颜色
            },
          },
        },
        grid3D: {
          viewControl: {
            projection: "orthographic",
          },
        },
        series,
      };
      // 使用刚指定的配置项和数据显示图表
      chart.setOption(option);
      // 响应式处理
      window.addEventListener("resize", () => {
        chart.resize();
      });
    },
  },
};
</script>

<style scoped>
.chart-container {
  width: 100%;
  height: 100%;
}
</style>
