<template>
  <div>
    <Echart
      :options="options"
      :id="id"
      :height="height"
      :width="'100%'"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {
        grid: {
          show: true,
          left: "10%",
          top: "20%",
          right: "10%",
          bottom: "20%",
          backgroundColor: "rgba(255, 255, 67, 0.1)",
          borderColor: "rgba(96, 67, 67, 1)",
        },
        legend: {
          data: ["温度值", "湿度值"], //图例名称
          right: 10, //调整图例位置
          top: 0, //调整图例位置
          icon: "line", //图例前面的图标形状
          textStyle: {
            //图例文字的样式
            color: "#a1a1a1", //图例文字颜色
            fontSize: 12, //图例文字大小
          },
        },
        title: {
          top: 0,
          left: "center", // left的值为'left', 'center', 'right'
          text: `温湿度周趋势图`,
          // 标题文本样式
          textStyle: {
            color: "#fff200", // 颜色
            fontStyle: "normal", // 字体风格,'normal','italic','oblique'
            fontWeight: "500", //字体粗细 'normal','bold','bolder','lighter',数值：100-700
            fontFamily: "sans-serif", //字体系列
            fontSize: 16, //字体大小
          },
        },
        xAxis: {
          type: "category",
          data: ["8-10", "8-11", "8-12", "8-13", "8-14", "8-15", "8-16"],
        },
        yAxis: {
          type: "value",
          name: "（℃）",
          min: "dataMin", // 设置y轴的最小值为数据最小值
          max: "dataMax", // 设置y轴的最大值为数据最大值
          interval: 1000,
          nameLocation: "left",
          // nameTextStyle: {
          //   fontSize: 14,
          //   padding: [200, 70, -10, 0],
          // },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#ff0",
            },
          },
        },
        series: [
          {
            name: "温度值",
            data: [20, 32, 30, 24, 18, 20, 22],
            type: "line",
            smooth: true,
          },
          // {
          //   name: "湿度值",
          //   data: [18, 22, 24, 23, 20, 23, 20],
          //   type: "line",
          //   smooth: true,
          // }
        ],
      },
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
      dafault: "echartsi",
    },
    height:{
      type: String,
      dafault: "120px",
    }
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = newData;
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
