<template>
  <div>
    <Echart
      :options="options"
      :id="ids"
      :height="height"
      :width="'100%'"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      options: {},
      height: '150px',
      ids: "echartsi"
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({}),
    },
    heightX: {
      type: Number,
      default: 200,
    },
    id: {
      type: String,
      default: "echartsi",
    }
  },
  watch: {
    cdata: {
      handler(newData) {
        this.height = this.heightX+"px"
        this.ids = this.id
        this.options = newData
      },
      immediate: true,
      deep: true
    },
  }
};
</script>
